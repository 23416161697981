export const API_URL = "https://api.think-hubet.com/api/v1";
export const URL = "https://api.think-hubet.com";
 //new env
 //export const API_URL = "http://localhost:3001/api/v1";
 //export const URL = "http://localhost:3001";
export const TOKEN_KEY = "token";
export const USER_KEY = "USER";
export const PUBLIC_URLS = {
  SIGNIN_API: "/auth/",
};
