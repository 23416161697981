import { OrderedListOutlined, TableOutlined } from "@ant-design/icons";
import { List, Table,message as alerts, Col, Row, Button, Tag, Switch, ShowButton, Card, Select, Spin, useCustom, useApiUrl, Input, Form } from "@pankod/refine";
import { Pagination } from "antd";
import { toggleUserStatus, getUsers, passPayment, } from "apis/users/users.api";
import { deleteReference, getPaymentReference} from "apis/payedbyreference/payedbyreference";
import { openNotification } from "components/feedback/notification";
import { ChangeEvent, useEffect, useState } from "react";



const { Option } = Select;

export const PaybyReferenceNoList: React.FC = () => {
  const [users, setUsers] = useState([]);
  const [printusers, setPrintusers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gradeFilter, setGradeFilter] = useState();
  const [message, setMessage] = useState('');
  const [current, setCurrent] = useState(1);
  const [updated, setUpdated] = useState(false);
  
  var limit = 10;
  const [total, setTotal] = useState(0);
  var offset = 0;
  const [phone, setPhone] = useState("");
  const [activateSearch,  setActivateSearch] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [view, setView] = useState(1);
  const changePageProps = (page:number, pageSize:number) => {
    limit = pageSize;
    offset = page * limit - limit;
    getUsersData();
  }
 


  useEffect(() => {
    getUsersData();
  }, [updated]);

 const handlePaymentPass=(passId: string)=>{
  passPayment(passId)
  .then((res: any) => {
   if(res.data.message == "successfully payed")
   {
    alerts.success('subscription successfully payed');
    setUpdated(!updated);
   }else{
    console.log(res)
   }
   
  }) 
 }
 const handleDeleteReference=(passId: string)=>{
  deleteReference(passId)
  .then((res: any) => {
    console.log(res);
   if(res.data.message == "successfully removed reference")
   {
    alerts.success('successfully removed reference');
    setUpdated(!updated);
   }else{
    console.log(res)
   }
   
  }) 
 }

 useEffect(()=>{
     console.log(users);
 })

  const getUsersData = () => {
    setIsLoading(true);
    getPaymentReference({offset, limit})
      .then((res: any) => {
        setCurrent(res.data.metadata.offset/res.data.metadata.limit + 1);
        setTotal(res?.data?.metadata.total);
        setUsers(res?.data?.users);
      })
      .catch((e: any) => {
        openNotification(`${e?.data?.message}`, "error");
      })
      .finally(() => setIsLoading(false));
  };

  const _toggleUserStatus = (id: string) => {
    setIsLoading(true);
    toggleUserStatus(id)
      .then(() => {
        getUsersData();
        openNotification(
          "User status has been updated successfully!",
          "success"
        );
      })
      .catch((e: any) => {
        openNotification(`${e?.data?.message}`, "error");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Row>
      <Col span={24}>
      
      </Col>
      <Col span={24}>
        <List>
          <Table  pagination={false} dataSource={users} loading={isLoading} rowKey="id">
   
            
            <Table.Column dataIndex="firstname" title="First Name" />
            <Table.Column dataIndex="lastname" title="Last Name" />
            <Table.Column dataIndex="refno" title="Reference NO" />
           
            <Table.Column
              dataIndex='hasActiveSubscription'
              title="Subscription start"
              render={(hasActiveSubscription:boolean, obj:any) => {
                  return <Tag color={hasActiveSubscription ? 'success':'red'} className='text-center mx-1'>
                  <span className="text-sm">
                    
                    {new Date(obj.subscriptionStartsAt).toLocaleDateString()}
                    <br />
                    {new Date(obj.subscriptionStartsAt).toLocaleTimeString()}
                  </span>
                </Tag>;
              }}
            />
            <Table.Column
              dataIndex='hasActiveSubscription'
              title="Subscription end"
              render={(hasActiveSubscription:boolean, obj:any) => {
                return<Tag color={hasActiveSubscription ? 'success':'red'} className='text-center'>
                  
                  <span className="text-sm">
                    {new Date(obj.subscriptionExpiresAt).toLocaleDateString()}
                    <br />
                    {new Date(obj.subscriptionExpiresAt).toLocaleTimeString()}
                  </span>
                </Tag> ;
              }}
            /> 
         
            
            <Table.Column
              title="Actions"
              render={(user) => {
                return user?.roles?.find(
                  (role: any) => role.name === "admin"
                ) ?    <Button onClick={()=>handlePaymentPass(user.userid)}>pay</Button> : (
                  <div className="flex gap-1 items-center">
                    <Button onClick={()=>handlePaymentPass(user.userid)}>pay</Button>
                  </div>
                );
              }}
            />
            <Table.Column
              title="Actions"
              render={(user) => {
                return user?.roles?.find(
                  (role: any) => role.name === "admin"
                ) ?    <Button onClick={()=>handleDeleteReference(user.id)}>delete</Button> : (
                  <div className="flex gap-1 items-center">
                    <Button onClick={()=>handleDeleteReference(user.id)}>delete</Button>
                  </div>
                );
              }}
            />
          </Table>
             
        
          {total ? <Pagination className="self-end" defaultCurrent={1} current={current} total={total} onChange={changePageProps} /> : ''}
        </List>
      </Col>
    </Row>
  );
};
