import axios from "axios";
import { ILogin } from "interfaces/login";
import { PayedByReferenceEndPoint } from "./payedbyreference.endpoints";
import { httpService } from "helpers/api-helper";

export const getPaymentReference = (data: any): any => {
  return httpService
    .get(`${PayedByReferenceEndPoint.getPaymentReference}`, { params: data })
    .then((response) => {
      return response;
    })
};

export const createPaymentReference = (params: any): any => {
  return httpService
    .post(`${PayedByReferenceEndPoint.createPaymentReference}`)
    .then((response) => {
      return response;
    });
};

export const deleteReference= (userid: string): any=>{
  
  return httpService
  .delete(`${PayedByReferenceEndPoint.deleteReference}/${userid}`)
  .then((response) => {
    return response;
  });
}
